import "./main.scss";
import ParticleContainer from "../Particles";
import Menu from "../Menu";
import Social from "../SocialMedia";
import { NavLink } from "react-router-dom";
import transition from "../../transition";
import Lottie from "lottie-react";
import SpaceFloat from "../../Assets/animations/space.json";
// import React, { useRef, useEffect, useState } from "react";

function NotFound() {
  return (
    <div className="header-container-notfound">
      <ParticleContainer></ParticleContainer>
      <div className="maxWidth-notfound">
        <Menu />
        <Social />
        <div className="left-side-notfound">
          <div className="name-container-notfound">
            <p className="error-num">404</p>
            <p className="headertxt">
              Oops! It seems we've traversed the vast reaches of <span>cyberspace</span> but
              couldn't locate the page you're searching for.
            </p>
          </div>
          <div className="call-to-action-container">
            <p className="title">
              Don't fret, fellow explorer! Grab hold of this Home button and
              let's journey back to the familiar grounds of our homepage. Safe
              travels!
            </p>
            <div className="cntbtn-notfound">
              <NavLink to="/">
                <span>HOME</span>
              </NavLink>
              <div className="wave"></div>
            </div>
          </div>
        </div>
        <div className="right-side-notfound">
          <div className="profileImage-notfound">
            <Lottie
              animationData={SpaceFloat}
              loop={true}
              autoplay={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default transition(NotFound);
