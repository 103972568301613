import "./main.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { NavLink } from "react-router-dom";
import React, { useState, useEffect } from "react";

export default function SideNav() {
  // Initialize the active state from localStorage or set it to false by default
  const [isSkillsActive, setIsSkillsActive] = useState(
    localStorage.getItem("isSkillsActive") === "true"
  );

  const storedMenuColor = localStorage.getItem("menuColor");

  const [isOpen, setIsOpen] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const [menuColor, setMenuColor] = useState(storedMenuColor || "#fa3f3b");

  // Function to handle the click event and update the state after a timeout
  const handleBarClick = (barName, barColor) => {
    setMenuColor(barColor);

    if (window.innerWidth > 930) {
      setIsOpen(true);
    }
    else if (window.innerWidth <= 930) {
      setIsOpen(false);
    }

    setTimeout(() => {
      if (barName === "skills") {
        setIsSkillsActive(true);
      } else {
        setIsSkillsActive(false);
      }
    }, 500); // 500ms timeout
  };

  // Function to handle sliding menu

  const openCloseMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 930) {
        setIsOpen((prevIsOpen) => true);
        setIsMenu((prevIsOpen) => false);
      } else if (window.innerWidth <= 930) {
        setIsOpen((prevIsOpen) => false);
        setIsMenu((prevIsOpen) => true);
      }
    }

    // Add event listener to listen for resize events
    window.addEventListener("resize", handleResize);

    // Call handleResize once to set the initial state
    handleResize();

    // Remove event listener when component unmounts
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Use useEffect to update localStorage when isSkillsActive changes
  useEffect(() => {
    if (!storedMenuColor) {
      localStorage.setItem("menuColor", "#fa3f3b"); // Set the base color if localStorage is empty
    }

    localStorage.setItem("isSkillsActive", isSkillsActive);
    localStorage.setItem("menuColor", menuColor);
  }, [isSkillsActive, menuColor]);

  return (
    <>
      {isMenu === true && isOpen === false && (
        <div className="bars-cont" onClick={openCloseMenu}>
          <div className="bar1" style={{ backgroundColor: menuColor }}></div>
          <div className="bar2" style={{ backgroundColor: menuColor }}></div>
          <div className="bar3" style={{ backgroundColor: menuColor }}></div>
        </div>
      )}
      {isOpen === true && (
        <div
          className={`bar-container ${isSkillsActive ? "skills-active" : ""}`}
        >
          <div
            className={`bar`}
            onClick={() => handleBarClick("home", "#fa3f3b")}
          >
            <NavLink to="/">
              <i className={`fas fa-house`}></i>
            </NavLink>
            <div className={`${isSkillsActive ? "skills-active" : ""}`}>
              <p>HOME</p>
            </div>
          </div>

          <div
            className={`bar`}
            onClick={() => handleBarClick("about", "#a4d6d8")}
          >
            <NavLink to="/about">
              <i className={`fas fa-user`}></i>
            </NavLink>
            <div className={`${isSkillsActive ? "skills-active" : ""}`}>
              <p>ABOUT ME</p>
            </div>
          </div>

          <div
            className={`bar`}
            onClick={() => handleBarClick("skills", "#6e7c87")}
          >
            <NavLink to="/skills">
              <i className={`fas fa-grip-vertical`}></i>
            </NavLink>
            <div className={`${isSkillsActive ? "skills-active" : ""}`}>
              <p>SKILLS</p>
            </div>
          </div>

          <div
            className={`bar`}
            onClick={() => handleBarClick("projects", "#ff4400")}
          >
            <NavLink to="/projects">
              <i className={`fas fa-folder`}></i>
            </NavLink>
            <div className={`${isSkillsActive ? "skills-active" : ""}`}>
              <p>PROJECTS</p>
            </div>
          </div>

          <div
            className={`bar`}
            onClick={() => handleBarClick("contact", "#6b2025")}
          >
            <NavLink to="/contact">
              <i className={`fas fa-envelope`}></i>
            </NavLink>
            <div className={`${isSkillsActive ? "skills-active" : ""}`}>
              <p>CONTACT</p>
            </div>
          </div>

          {isMenu === true && (
            <div className="bars-cont-close" onClick={openCloseMenu}>
              <div
                className="bar1-close"
                style={{ backgroundColor: menuColor }}
              ></div>
              <div
                className="bar2-close"
                style={{ backgroundColor: menuColor }}
              ></div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
