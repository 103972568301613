import "./main.scss";
import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { RiArrowGoBackFill } from "react-icons/ri";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Pagination, EffectFade } from "swiper";

export default function ProjectView({
  selectedProject,
  viewOpen,
  setViewOpen,
  setViewMini,
  viewMini,
}) {
  const handleClose = () => {
    setViewOpen(false);
    setViewMini(false);
  };

  const handleMiniClose = () => {
    setViewMini(false);
  };

  const handleMiniOpen = () => {
    setViewMini(true);
  };

  return (
    <div className="viewContainer">
      <div className="viewMain">
        <div className="leftSideView">
          <h1 className="titleView">{selectedProject.name}</h1>
          <p className="infoView">{selectedProject.viewText}</p>

          {selectedProject.seeCode === true && (
            <a
              className="githubLink"
              target="_blank"
              rel="noreferrer"
              href={selectedProject.link}
            >
              See code on GitHub
            </a>
          )}

          {selectedProject.seeSite === true && (
            <a
              className="githubLink"
              target="_blank"
              rel="noreferrer"
              href={selectedProject.link}
            >
              Visit Website
            </a>
          )}

          {selectedProject.techStack.length > 0 && (
            <div className="languagesContainer">
              <p className="languagesHeader">Tech Stack:</p>
              <div className="stackListContainer">
                <ul className="stackList">
                  {selectedProject.techStack.map((techStack, index) => (
                    <li className="stackItems" key={index}>
                      {techStack}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
        <div className="rightSideView">
          <div className="closeBtnView" onClick={handleClose}>
            <div className="line1All"></div>
            <div className="line2All"></div>
          </div>
          <Swiper
            spaceBetween={30}
            effect={"fade"}
            autoplay={{
              delay: 3500,
              disableOnInteraction: true,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[EffectFade, Pagination]}
            loop={true}
            className="mySwiper"
          >
            {selectedProject.imageMini.map((imageMini, index) => (
              <SwiperSlide key={index}>
                <img src={imageMini} alt="" />
              </SwiperSlide>
            ))}

            {selectedProject.videoAvailable === true &&
              selectedProject.videoLink.map((videoLink, index) => (
                <SwiperSlide key={index}>
                  <iframe
                    src={videoLink}
                    width="100%"
                    height="100%"
                    frameborder="0"
                    allowfullscreen={false}
                    title="OwlGuard demo video"
                    style={{
                      width: 100 + "%",
                      height: 100 + "%",
                      left: 0 + "px",
                      top: 0 + "px",
                    }}
                  ></iframe>
                </SwiperSlide>
              ))}
          </Swiper>

          <div className="viewDetails">
            {selectedProject.seeCode === true && (
              <a
                className="githubLinkMiniImage"
                target="_blank"
                rel="noreferrer"
                href={selectedProject.link}
              >
                See code on GitHub
              </a>
            )}
            {selectedProject.seeSite === true && (
              <a
                className="githubLinkMiniImage"
                target="_blank"
                rel="noreferrer"
                href={selectedProject.link}
              >
                Visit Website
              </a>
            )}
            <p className="detailsTextBtn" onClick={handleMiniOpen}>
              See Details
            </p>
          </div>
        </div>
        {viewMini === true && (
          <div className="miniInfo">
            <div className="closeMiniContainer" onClick={handleMiniClose}>
              <RiArrowGoBackFill />
            </div>
            <h1 className="titleViewMini">{selectedProject.name}</h1>
            <p className="infoViewMini">{selectedProject.viewText}</p>

            {selectedProject.seeCode === true && (
              <a
                className="githubLinkMini"
                target="_blank"
                rel="noreferrer"
                href={selectedProject.link}
              >
                See code on GitHub
              </a>
            )}
            {selectedProject.seeSite === true && (
              <a
                className="githubLinkMini"
                target="_blank"
                rel="noreferrer"
                href={selectedProject.link}
              >
                Visit Website
              </a>
            )}

            {selectedProject.techStack.length > 0 && (
              <div className="languagesContainerMini">
                <p className="languagesHeaderMini">Tech Stack:</p>
                <div className="stackListContainerMini">
                  <ul className="stackListMini">
                    {selectedProject.techStack.map((techStack, index) => (
                      <li className="stackItemsMini" key={index}>
                        {techStack}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
