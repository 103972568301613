import SectionInfo from '../SectionInfo';
import './main.scss';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import transition from "../../transition";

const title = "The Mind Behind the Pixels";
const text = `Hello! My name is Jugoslav Lozanovic, and I am a web developer and designer based in London, Ontario. I graduated from Fanshawe College, where I completed the Internet Applications and Web Development program. 

I am passionate about creating visually stunning and user-friendly websites and applications that provide seamless experiences for users. I am constantly learning and staying up-to-date with the latest web technologies, so I can provide my clients with the most innovative and effective solutions for their needs.

Whether you're a startup, small business, or large corporation, I am committed to working closely with you to understand your unique requirements and deliver customized solutions that meet and exceed your expectations.`;

function About() {
    return (
      <div className="about-container">
        <div className="maxWidthAbout">
          <div className="left-side-content">
            <SectionInfo pageName="ABOUT ME" pageTitle={title} pageText={text} />
            
          </div>
          <div className="right-side-content">
            <div className="profile-card">
              <div className='infoCard'>
                  <h2>Jugoslav Lozanovic</h2>
                  <p>Web Developer / Designer</p>
              </div>

              {/* <div className="hover-text">
                <p>
                  "Design is the art of turning chaos into clarity. It is the
                  bridge that connects creativity and functionality."
                </p>
                <span>
                  <FontAwesomeIcon icon={faCommentDots} beat />
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
}

export default transition(About);