import SectionInfo from "../SectionInfo";
import "./main.scss";
import transition from "../../transition";
import Card from "./Card";
import "@fortawesome/fontawesome-free/css/all.min.css";

const title = "Your Vision, My Code";
const text =
  "Welcome to my skills page! Here's a glimpse of what I bring to the table. With these skills, I'm ready to craft captivating websites and more.";

function Skills() {
  const mySkills = [
    {
      id: "html",
      name: "HTML",
      percentage: 95,
      color: "#E34F26",
      stopColor: "#FFA54F",
    },
    {
      id: "css",
      name: "CSS/SCSS",
      percentage: 90,
      color: "#1572B6",
      stopColor: "#A0C3E1",
    },
    {
      id: "javascript",
      name: "JavaScript",
      percentage: 85,
      color: "#F7DF1E",
      stopColor: "#F9F7A6",
    },
    {
      id: "react",
      name: "React",
      percentage: 85,
      color: "#61DAFB",
      stopColor: "#9EE9FF",
    },
    {
      id: "react-native",
      name: "React Native",
      percentage: 80,
      color: "#5A68D0",
      stopColor: "#9B9FCB",
    },
    {
      id: "php",
      name: "PHP",
      percentage: 85,
      color: "#777BB4",
      stopColor: "#A8A8C3",
    },
    {
      id: "sql",
      name: "SQL",
      percentage: 70,
      color: "#FFD700",
      stopColor: "#FFEFAF",
    },
    {
      id: "photoshop",
      name: "Photoshop",
      percentage: 90,
      color: "#001F3F",
      stopColor: "#416A97",
    },
    {
      id: "illustrator",
      name: "Illustrator",
      percentage: 90,
      color: "#FF7A00",
      stopColor: "#FFAD66",
    },
    {
      id: "adobe-xd",
      name: "Adobe XD",
      percentage: 95,
      color: "#FF61E4",
      stopColor: "#FF8DE7",
    },
  ];

  return (
    <div className="skills-container">
      <div className="maxWidthSkills">
        <div className="title-holder">
          <SectionInfo pageName="SKILLS" pageTitle={title} pageText={text} />
        </div>
        <div className="grid-holder">
          {mySkills.map((skill, index) => (
            <Card
              key={index}
              name={skill.name}
              percentage={skill.percentage}
              color={skill.color}
              stopColor={skill.stopColor}
              id={skill.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default transition(Skills);
