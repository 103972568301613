// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SectionInfo from "../SectionInfo";
import owlGuard from "../../Assets/owlguard.png";
import owlWatch from "../../Assets/OwlWatch.png";
import jTech from "../../Assets/JTech.png";
import digitalEra from "../../Assets/DigitalEraDesign.png";
import balkazon from "../../Assets/Balkazon.png";

/* JTech images for Mini View */
import JTech1 from "../../Assets/projects/JTech/1.png";
import JTech2 from "../../Assets/projects/JTech/2.png";
import JTech3 from "../../Assets/projects/JTech/3.png";

/* OwlWatch images for Mini View */
import OwlWatch1 from "../../Assets/projects/OwlWatch/1.png";
import OwlWatch2 from "../../Assets/projects/OwlWatch/2.png";
import OwlWatch3 from "../../Assets/projects/OwlWatch/3.png";
import OwlWatch4 from "../../Assets/projects/OwlWatch/4.png";

/* OwlGuard images for Mini View */
import OwlGuard1 from "../../Assets/projects/OwlGuard/1.png";
import OwlGuard2 from "../../Assets/projects/OwlGuard/2.png";
import OwlGuard3 from "../../Assets/projects/OwlGuard/3.png";

/* Digital Era Design images for Mini View */
import Ded1 from "../../Assets/projects/DED/1.png";
import Ded2 from "../../Assets/projects/DED/2.png";
import Ded3 from "../../Assets/projects/DED/3.png";
import Ded4 from "../../Assets/projects/DED/4.png";

/* Balkazon images for Mini View */
import Balkazon1 from "../../Assets/projects/Balkazon/1.png";
import Balkazon2 from "../../Assets/projects/Balkazon/2.png";
import Balkazon3 from "../../Assets/projects/Balkazon/3.png";
import Balkazon4 from "../../Assets/projects/Balkazon/4.png";

import ProjectView from "./ProjectView";
import { useState } from "react";
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  EffectFade,
  Mousewheel,
} from "swiper";
import "swiper/swiper-bundle.min.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/grid";

import "./main.scss";
import transition from "../../transition";

// import required modules
// import { EffectCoverflow, Pagination } from "swiper";

function Projects() {
  const [selectedProject, setSelectedProject] = useState(null);
  const [viewOpen, setViewOpen] = useState(false);
  const [viewMini, setViewMini] = useState(false);

  const projectList = [
    {
      name: "Balkazon Shop",
      date: "28 March 2024",
      text: "With a clean and intuitive design, navigating through our collection of women's fitness clothing is a seamless experience. Our website not only prioritizes aesthetics but also functionality, ensuring that every visitor can easily find the perfect pieces to enhance their workout routines. ",
      image: balkazon,
      imageMini: [Balkazon1, Balkazon2, Balkazon3, Balkazon4],
      seeCode: false,
      seeSite: true,
      link: "https://www.balkazon.ca/",
      techStack: [],
      viewText:
        "Balkazon, a dynamic e-commerce platform built to cater to fitness enthusiasts, harnesses the power of sleek design and intuitive functionality. With a focus on gym clothing, Balkazon offers a seamless shopping experience where users can effortlessly explore a curated selection of premium activewear. From cutting-edge leggings to breathable tops, each item is meticulously chosen to enhance both performance and style.",
      videoAvailable: false,
    },
    {
      name: "Digital Era Design",
      date: "23 January 2024",
      text: 'The "Digital Era Design" logo embodies the essence of modernity, innovation, and technological advancement. Overall, the logo serves as a powerful visual representation of the intersection between technology and creativity.',
      image: digitalEra,
      imageMini: [Ded1, Ded2, Ded3, Ded4],
      seeCode: false,
      seeSite: false,
      link: "#",
      techStack: ["Adobe Photoshop", "Adobe Illustrator"],
      viewText:
        'The "Digital Era Design" logo is a masterful fusion of elegance and innovation. At its core, the logo showcases a captivating play on typography, ingeniously intertwining the initials "D" and "E" to form a cohesive visual narrative. The hallmark of the logo is the clever manipulation of the letterforms. A bold, reversed "D" serves as the anchor, symbolizing the foundation of digital innovation. Seamlessly connected to it is the lowercase "e," representing the fluidity and adaptability inherent in modern design practices. Completing the ensemble is another "D," gracefully melding with its counterpart to form a harmonious whole.',
      videoAvailable: false,
    },
    {
      name: "Owl Guard",
      date: "16 December 2022",
      text: "Owl Guard is an app that lets you quickly send your location to your emergency contacts via email or SMS. Use it when you feel threatened or in danger.",
      image: owlGuard,
      imageMini: [OwlGuard1, OwlGuard2, OwlGuard3],
      seeCode: true,
      seeSite: false,
      link: "https://github.com/jugoslavlozanovic/OwlGuard",
      techStack: ["React Native"],
      viewText:
        "Owl Guard, a robust mobile application developed using React Native, empowers users with the ability to swiftly share their current location with designated emergency contacts through email or SMS. This user-friendly app proves invaluable when individuals find themselves in situations where they feel threatened or in imminent danger. Leveraging the versatility of React Native, Owl Guard ensures a seamless and responsive user experience across both iOS and Android platforms. Its intuitive design and straightforward functionality make it an essential tool for personal safety, providing users with a reliable means of alerting their emergency contacts when they need assistance the most.",
      videoAvailable: true,
      videoLink: ["https://streamable.com/e/yrvuhk"],
    },
    {
      name: "Owl Watch",
      date: "12 April 2023",
      text: "Owl Watch is a web application that allows employees to log their work hours and track their time. It simplifies the process of time tracking and provides a reliable way for employers to monitor employee attendance.",
      image: owlWatch,
      imageMini: [OwlWatch1, OwlWatch2, OwlWatch3, OwlWatch4],
      seeCode: true,
      seeSite: false,
      link: "https://github.com/jugoslavlozanovic/OwlWatch",
      techStack: ["React", "SCSS"],
      viewText:
        "Introducing Owl Watch, an innovative web application meticulously crafted using React and SCSS. Tailored for efficiency, Owl Watch serves as a comprehensive solution for employees to effortlessly log their work hours and monitor their time effectively. By harnessing the power of React and the styling capabilities of SCSS, this web application offers a seamless and visually appealing user interface. Moreover, Owl Watch is seamlessly connected to Firebase, enhancing its functionality by allowing users to conveniently request time off. This integration with Firebase not only facilitates efficient time-off management but also ensures that the application is scalable and responsive.",
      videoAvailable: false,
    },
    {
      name: "JTech",
      date: "10 November 2021",
      text: "JTech is a website designed for the purpose of selling mobile phones to customers. The website offers a variety of mobile phones and accessories for users to browse and purchase.",
      image: jTech,
      imageMini: [JTech1, JTech2, JTech3],
      seeCode: false,
      seeSite: false,
      link: "#",
      techStack: ["HTML", "CSS", "JavaScript"],
      viewText:
        "JTech is a modern and user-friendly e-commerce platform meticulously crafted for the purpose of connecting tech enthusiasts with cutting-edge mobile technology. Our website showcases a diverse range of high-quality mobile phones and accessories, providing a seamless browsing and shopping experience for our valued customers. Behind the scenes, JTech is powered by a robust technology stack. The website is meticulously coded using a combination of HTML, CSS, and JavaScript, harnessing the full potential of these web technologies to deliver a seamless and efficient online shopping environment. This dedication to web development excellence enables us to provide not only a visually stunning interface but also a fast, secure, and reliable platform for our users.",
      videoAvailable: false,
    },
  ];

  SwiperCore.use([Navigation, Pagination, Autoplay, EffectFade, Mousewheel]);

  const handleSeeMoreClick = (project) => {
    // Update the state with the selected project
    setSelectedProject(project);
    setViewOpen(true);
  };

  return (
    <div className="projectsContainer">
      <div className="maxWidthProjects">
        <div className="title-holder">
          <SectionInfo
            pageName="PROJECTS"
            pageTitle="Digital Dreamscape"
            pageText={`These projects represent just a glimpse of my work as a web developer. Each one reflects my commitment to crafting intuitive, visually appealing, and high-performing web solutions. I'm continually seeking new challenges and opportunities to expand my skills and contribute to the ever-evolving world of web development.`}
          />
        </div>

        <Swiper
          slidesPerView={1}
          spaceBetween={4}
          centeredSlides={false}
          pagination={{
            clickable: true,
          }}
          /*           autoplay={{
            delay: 2500,
            disableOnInteraction: true,
          }} */
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1600: {
              slidesPerView: 3,
            },
          }}
          modules={[Pagination]}
          className="mySwiper"
          loop={false}
          resistance={true}
        >
          {projectList.map((projectListItem, index) => (
            <SwiperSlide className="blog-slider__item swiper-slide" key={index}>
              <div className="blog-slider__img">
                <img src={projectListItem.image} alt="" />
              </div>
              <div className="blog-slider__content">
                <div className="holder">
                  <p className="__code">{projectListItem.date}</p>
                  <div className="__title">{projectListItem.name}</div>
                  <div className="__text">{projectListItem.text}</div>
                </div>

                <a
                  href="#"
                  className="blog-slider__button"
                  onClick={() => {
                    handleSeeMoreClick(projectListItem);
                  }}
                >
                  SEE MORE
                </a>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {viewOpen === true && (
          <ProjectView
            selectedProject={selectedProject}
            viewOpen={viewOpen}
            setViewOpen={setViewOpen}
            setViewMini={setViewMini}
            viewMini={viewMini}
          />
        )}
      </div>
    </div>
  );
}

export default transition(Projects);
