import "./main.scss";
import Lottie from "lottie-react";
import SuccessAnimation from "../../../Assets/animations/Success.json";

export default function Success({setModalTrue}) {
  return (
    <div className="fullWidthContainer">
      <div className="modal">
        <Lottie animationData={SuccessAnimation} loop={false} autoplay={true} />
        <h1>Success</h1>
        <p className="successText">
          Thank you for reaching out to me! <br />Your message has been successfully
          received.
        </p>
        <button onClick={setModalTrue}>OK</button>
      </div>
    </div>
  );
}
