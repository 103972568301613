import "./main.scss";

export default function Card({ name, percentage, color, stopColor, id }) {
  // Define an array of gradient colors where the first color is based on the "color" prop
  const gradientColors = [color, stopColor]; // Use the stopColor prop

  // Calculate gradient stops based on the percentage
  const stops = gradientColors.map((color, index, array) => ({
    color,
    offset: (index / (array.length - 1)) * 100, // Distribute evenly
  }));

  return (
    <div className="cardSkill">
      <div className="circle">
        <svg>
          <defs>
            <linearGradient
              id={`gradient-${id}`}
              x1={"50%"}
              y1={"0%"}
              x2={"50%"}
              y2={"100%"}
            >
              {stops.map((stop, index) => (
                <stop
                  key={index}
                  offset={`${stop.offset}%`}
                  stopColor={stop.color}
                />
              ))}
            </linearGradient>
          </defs>
          <circle
            cx={70}
            cy={70}
            r={70}
            style={{
              strokeDashoffset: `calc(440 - (440 * ${percentage}) / 100)`,
              stroke: `url(#gradient-${id})`, // Use the unique gradient ID
            }}
          ></circle>
        </svg>
        <div className="inside">
          <span>{percentage}%</span>
          <div className="dot" style={{transform: `rotate(calc(3.6deg * ${percentage}))`, }} ></div>
        </div>
      </div>
      <h4>{name}</h4>
    </div>
  );
}
