import "./main.scss";
import SectionInfo from "../SectionInfo";
import Success from "./Success";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import transition from "../../transition";
import Social from "../SocialMedia";
import { useState } from "react";

function Contact() {
  const form = useRef();
  const [Fname, setFname] = useState("");
  const [Lname, setLname] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  const [Honeypot, setHoneypot] = useState("");
  const [checkFName, setCheckFName] = useState(false);
  const [checkLName, setCheckLName] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [checkMessage, setCheckMessage] = useState(false);
  const [Error, setError] = useState([]);
  const [showModal, setShowModal] = useState(false);

  function handleFname(event) {
    setFname(event.target.value);
  }

  function handleLname(event) {
    setLname(event.target.value);
  }

  function handleEmail(event) {
    setEmail(event.target.value);
  }

  function handleMessage(event) {
    setMessage(event.target.value);
  }

  function handleHoneypot(event) {
    setHoneypot(event.target.value);
  }

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_abz1yed",
        "template_urwcqxh",
        form.current,
        "vN2hOnktK2C2s0LLS"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  const validation = (e) => {
    e.preventDefault();
    if (Error.length > 0) {
      setError([]);
    }

    setCheckFName(false);
    setCheckLName(false);
    setCheckEmail(false);
    setCheckMessage(false);

    let hasValidationErrors = false;

    if (Fname.length === 0) {
      setError((Error) => [...Error, "Please enter your first name!"]);
      setCheckFName(true);
      hasValidationErrors = true;
    }
    if (Lname.length === 0) {
      setError((Error) => [...Error, "Please enter your last name!"]);
      setCheckLName(true);
      hasValidationErrors = true;
    }
    if (Email.length < 6) {
      setError((Error) => [...Error, "Please enter a valid Email!"]);
      setCheckEmail(true);
      hasValidationErrors = true;
    }
    if (Message.length === 0) {
      setError((Error) => [...Error, "Please enter a message!"]);
      setCheckMessage(true);
      hasValidationErrors = true;
    }
    if (Honeypot.length > 0) {
      setError((Error) => [...Error, "BOT DETECTED!"]);
      hasValidationErrors = true;
    }

    if (!hasValidationErrors) {
      sendEmail(e); // Call the sendEmail function only if there are no validation errors
      setFname("");
      setLname("");
      setEmail("");
      setMessage("");
      setModalTrue();
    }
  };

  const setModalTrue = () => {
    setShowModal(!showModal);
  }

  return (
    <div className="contact-container">
      {showModal && <Success setModalTrue={setModalTrue} />}
      <div className="maxWidthContact">
        <form ref={form} onSubmit={validation}>
          <input
            className={checkFName ? "errorInput" : ""}
            onChange={handleFname}
            type="text"
            placeholder="First Name..."
            name="first_name"
            autoComplete="off"
            value={Fname}
          ></input>
          <input
            className={checkLName ? "errorInput" : ""}
            type="text"
            placeholder="Last Name..."
            name="last_name"
            autoComplete="off"
            onChange={handleLname}
            value={Lname}
          ></input>
          <input
            className={checkEmail ? "errorInput" : ""}
            type="email"
            placeholder="Email..."
            name="email"
            autoComplete="off"
            onChange={handleEmail}
            value={Email}
          ></input>
          <textarea
            className={checkMessage ? "errorInput" : ""}
            placeholder="Message..."
            name="message"
            onChange={handleMessage}
            value={Message}
          ></textarea>
          <input
            type="text"
            style={{ display: "none" }}
            name="honeypot"
            autoComplete="off"
            onChange={handleHoneypot}
            value={Honeypot}
          />
          <button type="submit">Submit</button>
          <Social />
        </form>

        <div className="info-container">
          <SectionInfo
            pageName="CONTACT"
            pageTitle="Connect with Me"
            pageText={`Thank you for taking the time to view my portfolio! If you have any questions 
or would like to discuss a potential project, please don't hesitate to get in touch. 
        
Whether you need a project done or just want to chat, I'm always happy to 
connect with others in the industry. You can reach me by filling out the contact form or by using the contact information provided. I typically respond to all inquiries within 24-48 hours. 
        
I look forward to hearing from you!`}
          />
        </div>
      </div>
    </div>
  );
}

export default transition(Contact);
