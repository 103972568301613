import "./main.scss";
import ParticleContainer from "../Particles";
import Menu from "../Menu";
import Social from "../SocialMedia";
import { NavLink } from "react-router-dom";
import transition from "../../transition";
import ProfileImg from "../../Assets/mainimg.png";
// import React, { useRef, useEffect, useState } from "react";

function Header() {
  // const nameRef = useRef(null);

  // const ocRef = useRef(null);
  // const [text, setText] = useState("");
  // const [code, setCode] = useState("");

  // useEffect(() => {
  //   const nameElement = nameRef.current;
  //   const letters = "ABCDEFGHIJKLOMOPQRSTUVWXYZ";

  //   let texts = [
  //     "SOKMDUENBSHUWETXNI",
  //     "HEYDHSYENDLOXMANIZ",
  //     "QUDJETSNAPORLIDKVI",
  //     "EPALWIDMMSOWLUGJSD",
  //     "MEUSHJDKALWNRUSIMV",
  //     "TSJENFMDSPQOEIASUC",
  //     "AWHIDAIVESANVIUFEA",
  //     "IOWADJIANOCWEAUHFO",
  //     "AOIDADCHNUANCUUIAA",
  //     "RUIWEIDAIUAUIWHDAW",
  //     "OODIWOIQJCNUFHAGHE",
  //   ];

  //   setText(texts[Math.floor(Math.random() * 11)]);

  //   setTimeout(() => {
  //     let iterations = 0;

  //     const interval = setInterval(() => {
  //       nameElement.innerText = nameElement.innerText
  //         .split("")
  //         .map((letter, index) => {
  //           if (index < iterations) {
  //             return nameElement.dataset.value[index];
  //           }

  //           return letters[Math.floor(Math.random() * 26)];
  //         })
  //         .join("");

  //       if (iterations >= nameElement.dataset.value.length) {
  //         clearInterval(interval);
  //       }

  //       iterations += 1 / 3;
  //     }, 30);
  //   }, 2000);
  // }, []);

  // useEffect(() => {
  //   const ocElement = ocRef.current;
  //   const numbers = "01";

  //   let texts = [
  //     "011010010100110100111001",
  //     "010110010110101010011101",
  //     "100010110111001000010010",
  //     "110101110101010110001011"
  //   ];

  //   setCode(texts[Math.floor(Math.random() * 4)]);

  //   setTimeout(() => {
  //     let iterations = 0;

  //     const interval = setInterval(() => {
  //       ocElement.innerText = ocElement.innerText
  //         .split("")
  //         .map((number, index) => {
  //           if (index < iterations) {
  //             return ocElement.dataset.value[index];
  //           }

  //           return numbers[Math.floor(Math.random() * 2)];
  //         })
  //         .join("");

  //       if (iterations >= ocElement.dataset.value.length) {
  //         clearInterval(interval);
  //       }

  //       iterations += 1 / 3;
  //     }, 30);
  //   }, 4000);
  // }, []);

  return (
    <div className="header-container">
      <ParticleContainer></ParticleContainer>
      <div className="maxWidth">
        <Menu />
        <Social />
        <div className="left-side">
          <div className="name-container">
            {/* <p className="name" data-value="JUGOSLAVLOZANOVIC" ref={nameRef}>
              {text}
            </p>
            <p
              className="occupation"
              data-value="WEB DEVELOPER / DESIGNER"
              ref={ocRef}
            >
              {code}
            </p> */}
            <p className="headertxt">
              Empowering Businesses with <br />
              Seamless <span>Digital Solutions</span>
            </p>
          </div>
          <div className="call-to-action-container">
            <p className="title">Let's Build Your Vision</p>
            <div className="cntbtn">
              <NavLink to="/contact">
                <span>CONTACT</span>
              </NavLink>
              <div className="wave"></div>
            </div>
          </div>
        </div>
        <div className="right-side">
          <div className="profileImage">
            <img src={ProfileImg} alt="Profile" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default transition(Header);
