import './main.scss';

export default function SectionInfo({pageName, pageTitle, pageText}) {
    return (
      <div className="info-container">
        <div className="page">
          <ul>
            <li>
              <div></div>
            </li>
            <li>
              <div></div>
            </li>
            <li>
              <div></div>
            </li>
            <li>
              <div></div>
            </li>
            <li>
              <div></div>
            </li>
            <li>
              <p className="page-name">{pageName}</p>
            </li>
          </ul>
        </div>
        <p className="title">{pageTitle}</p>
        {pageText && <p className="info">{pageText}</p>}
      </div>
    );
}